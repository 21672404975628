<div class="chatbot-component">

  <div class="chatbot-component__messages" *ngIf="(messages | async).length">
    <ng-container *ngFor="let message of (messages | async)">
      <ng-container *ngIf="message.ai">
        <div
          class="chatbot-component__message chatbot-component__message_by-ai"
          [ngClass]="{
            'chatbot-component__message_by-ai_loading': message.isLoading | async,
            'chatbot-component__message_by-ai_error': message.error,
          }"
        >
          <div class="chatbot-component__message_avatar" [ngClass]="{
            'fa-fade': message.isLoading | async
          }">
            <app-icon-sparkle></app-icon-sparkle>
          </div>
          <div>
            <div [innerHTML]="formatAiMessage(message.text | async) | renderMath" class="chatbot-component__message_content"></div>
            <div *ngIf="(message.chapterLinks | async) as chapters" class="chatbot-component__message_attachments">
              <div *ngFor="let link of chapters" [routerLink]="link.urlPath"
                   class="chatbot-component__message_attachment link"
                   tabindex="0"
                   (keydown.enter)="openLink(link)"
              >
                <div class="column">{{link.column.title}}</div>
                <div class="chapter" [innerHTML]="link.chapter.title | renderMath"></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!message.ai">
        <div class="chatbot-component__message chatbot-component__message_by-user">
          <div [innerHTML]="message.text | async" class="chatbot-component__message_content"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div *ngIf="!isReadonly && (showChatControls$ | async)" class="chatbot-component__controls">
    <div class="chatbot-component__controls_feedback">
      <span class="chatbot-component__controls_feedback_text">War das hilfreich?</span>
      <span class="chatbot-component__controls_feedback_button" (click)="feedback('positive')"
            [ngClass]="{selected: (feedback$ | async) === 'positive'}"
            [matTooltip]="'Ja, meine Frage wurde geklärt'"
      >
        <i class="fas fa-thumbs-up"></i>
      </span>
      <span class="chatbot-component__controls_feedback_button" (click)="feedback('negative')"
            [ngClass]="{selected: (feedback$ | async) === 'negative'}"
            [matTooltip]="'Nein, meine Frage wurde nicht geklärt'"
      >
        <i class="fas fa-thumbs-down"></i>
      </span>
    </div>
    <div class="chatbot-component__controls_reset" (click)="reset()"
         [matTooltip]="'Setzt den Chat zurück. Du kannst danach einen neuen Chat starten'"
    >
      neu starten <i class="fas fa-arrows-rotate"></i>
    </div>
  </div>

  <div class="chatbot-component__inputs" *ngIf="!isReadonly && !messageLimitReached">
    <textarea
      #input
      (keydown.enter)="submit($event)"
      (input)="autoGrow()"
      [(ngModel)]="userInput"
      class="chatbot-component__inputs_input"
      placeholder="Neue Frage hier"
      [disabled]="(isReceivingMessage | async)"
    ></textarea>
    <button
      (click)="submit($event)"
      class="chatbot-component__inputs_submit"
      [disabled]="(isReceivingMessage | async)"
    >
      <i class="fas fa-paper-plane"></i>
    </button>
  </div>
  <div class="chatbot-component__notice" *ngIf="!isReadonly && messageLimitReached">
    Das Nachrichtenlimit für diese Unterhaltung wurde erreicht. Du kannst den Chat
    <strong class="chatbot-component__notice_action" (click)="reset()"
            [matTooltip]="'Setzt den Chat zurück. Du kannst danach einen neuen Chat starten'"
    >
      hier neu starten
    </strong>.
  </div>
</div>
