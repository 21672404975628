import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ICourse, IColumn } from '@models/course';
import { Router } from '@angular/router';
import {
  CourseNavigationContext,
  PageType
} from '@services/chapter-navigation.service';
import { LoginService } from '../../../core/auth/login.service';
import { ChapterDisplayMode, ChapterPriority, IChapter } from '@models/chapter';
import { ModalsService } from '@components/modals/modals.service';
import { ProfileService } from '@services/profile.service';
import { SpaService } from '@services/spa.service';
import { DisposeBag } from '@utils/DisposeBag';
import { BrowserService } from '@services/browser.service';
import { TranslationService } from '@services/translation.service';
import { ChapterService } from '@services/chapter.service';
import { TEST_IDS } from '../sidebar.constants';
import { RemoveWhitespacesPipe } from 'app/shared/pipes/removeWhiteSpaces';
import { IDialog } from '@components/modals/modal.types';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('chapterChildren') chapterChildren: QueryList<ElementRef>;
  @ViewChild('index') indexElement: ElementRef;
  @ViewChild('registerModal') private registerModal: IDialog;
  @ViewChild('buyModal') private buyModal: IDialog;
  @ViewChild('learningOverviewModal') public learningOverviewModal: IDialog;

  public TEST_IDS: typeof TEST_IDS = TEST_IDS;

  public chapterPriority = ChapterPriority;
  public logoUrl = './assets/images/logo-short-3-blue.png';
  public course: ICourse;
  public column: IColumn;
  public targetChapter: IChapter;
  public openChapters = [];
  public open = false;
  public isLearningOverview = false;
  private bag = new DisposeBag();
  public indexAnchor = 0;
  public lastScrollTop = 0;

  private _contactWhatsapp: string;
  private _contactEmail: string;
  private _technicalQuestionEmail: string | null

  public isSafari: boolean;

  constructor(
    public navContext: CourseNavigationContext,
    public loginService: LoginService,
    public modalService: ModalsService,
    private router: Router,
    private profileService: ProfileService,
    private spaService: SpaService,
    private browserService: BrowserService,
    private translate: TranslationService,
    private chapterService: ChapterService,
    private removeWhiteSpaces: RemoveWhitespacesPipe,
  ) {}

  public get contactWhatsapp(): string {
    if (!this._contactWhatsapp) {
      return this.translate.contactPhoneForURL();
    }
    return this._contactWhatsapp;
  }
  public get orgContactWhatsapp(): string {
    return  this.translate.contactPhoneForURL()
  }

  public get contactEmail(): string {
    if (!this._contactEmail) {
      return this.translate.contactEmailCoursesDefault();
    }
    return this._contactEmail;
  }
  public get orgContactEmail(): string {
    return this.translate.contactEmailCoursesDefault();
  }

  public get tooltipShown(): boolean {
    return !this.course
  }

  public get technicalContactsVisible(): boolean {
    return !!this.course
  }

  public get technicalContacts(): string {
    return this._technicalQuestionEmail
  }

  public logout(): void {
    this.loginService.logout();
  }

  public login(): void {
    this.loginService.login();
  }

  public register(): void {
    this.loginService.login(null, null, true);
  }

  public buyCourseModalOnCancel(): void {
    if (this.loginService.isAnonymous) {
      this.login();
    }
  }

  public getFirstChapterWithContent(column: IColumn) {
    return this.spaService.getFirstChapterWithContent(column);
  }

  selectChapter(chapter: IChapter, column: IColumn = null): void {
    this.open = false;

    if (this.chapterService.isChapterForbidden(chapter, this.course)) {
      if (
        this.chapterService.isChapterInMembersPreviewOnly(chapter, this.course)
      ) {
        this.modalService.open(this.registerModal);
      } else {
        this.modalService.open(this.buyModal);
      }
      return;
    }

    this.navContext.goToChapter(chapter, column);

    this.openChapter(chapter);

    if (chapter.displayMode == ChapterDisplayMode.includedInParent) {
      const heading = document.getElementById(
        'i' + chapter.orderString.replace(/,/g, '_')
      );
      if (heading) {
        heading.scrollIntoView({
          behavior: 'smooth'
        });
        heading.style.animationIterationCount = '1';
        heading.classList.toggle('focus');
        return;
      }
    }
  }

  openLearningOverview(): void {
    if (this.isLearningOverviewForbidden()) {
      this.modalService.open(this.learningOverviewModal);
    } else {
      const column = this.column || this.course.columns[0];
      this.router.navigateByUrl(
        '/courses/learningoverview/' +
          this.course.url_slug +
          (column ? '/' + column.url_slug : '')
      );
    }
  }

  isLearningOverviewForbidden(): boolean {
    return !this.course || !this.course.totalProgress;
  }

  orderCourse(): void {
    this.spaService.orderCourse(this.course);
    this.modalService.close(this.buyModal)
  }

  isChapterOpen(chapter: IChapter): boolean {
    return this.openChapters.includes(chapter._id);
  }

  isChapterForbidden(chapter: IChapter): boolean {
    return this.chapterService.isChapterForbidden(chapter, this.course);
  }

  isChapterInMembersPreviewOnly(chapter: IChapter): boolean {
    return this.chapterService.isChapterInMembersPreviewOnly(
      chapter,
      this.course
    );
  }

  columnClick(column: IColumn): void {
    ///courses/course/{{course.url_slug}}/{{col.url_slug}}/{{col.chapters.length ? getFirstChapterWithContent(col).url_slug : ''}}"
    const firstChapter = this.getFirstChapterWithContent(column);
    if (firstChapter) {
      this.selectChapter(firstChapter, column);
    } else {
      this.router.navigateByUrl(
        '/courses/course/' + this.course.url_slug + '/' + this.column.url_slug
      );
    }
  }

  openChapter(chapter: IChapter): void {
    if (!this.openChapters.includes(chapter._id)) {
      this.openChapters.push(chapter._id);
    }
  }

  closeChapter(chapter: IChapter): void {
    this.openChapters = this.openChapters.filter((id) => id !== chapter._id);
  }

  toggleChapter(chapter: IChapter): void {
    if (this.openChapters.includes(chapter._id)) {
      this.closeChapter(chapter);
    } else {
      this.openChapter(chapter);
    }
  }

  openChapterRecursive(chapter: IChapter, chapters: Array<IChapter>): boolean {
    let includes = false;

    if (!(chapter && chapters)) {
      return false;
    }

    chapters.forEach((parent) => {
      let isChild = false;
      if (parent.subchapters && parent.subchapters.length) {
        isChild = this.openChapterRecursive(chapter, parent.subchapters);

        if (isChild) {
          includes = isChild;
          this.openChapter(parent);
        }
      }
      if (parent._id === chapter._id) {
        includes = true;
        this.openChapter(parent);
      }
    });

    return includes;
  }

  public getProgressOfCourse(course: ICourse) {
    return (course.userData?.progress?.processed || 0) / course.stats.maxProgress
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const currentScrollTop = document.scrollingElement.scrollTop;
    const diff = currentScrollTop - this.lastScrollTop;
    if (!this.indexElement) {
      // not in course
      return;
    }
    const element = this.indexElement.nativeElement;
    const rect = element.getBoundingClientRect();
    const height = rect.height;

    const headingOffset = 46;
    const bottomLinksHeight = 64;

    this.indexAnchor -= diff;
    this.indexAnchor = Math.min(0, this.indexAnchor);
    this.indexAnchor = Math.max(
      window.innerHeight - height - bottomLinksHeight - headingOffset,
      this.indexAnchor
    );

    element.style.setProperty('--top', this.indexAnchor + headingOffset + 'px');

    if (window.innerHeight < height + bottomLinksHeight + headingOffset) {
      element.classList.add('overflowing');
    } else {
      element.classList.remove('overflowing');
    }

    this.lastScrollTop = currentScrollTop;
  }

  ngOnInit(): void {
    this.bag.add(this.profileService.me().subscribe(() => null));

    this.isSafari = this.browserService.isSafari();

    this.bag.add(
      this.spaService.chapter.subscribe((chapter) => {
        this.targetChapter = chapter;
        if (this.column) {
          this.openChapterRecursive(chapter, this.column.chapters);
        }
      })
    );

    this.bag.add(
      this.spaService.pageType.subscribe((pageType) => {
        this.isLearningOverview = pageType == PageType.progress;
      })
    );

    this.bag.add(
      this.spaService.column.subscribe((column) => {
        if (column) {
          if (!this.column || this.column.url_slug != column.url_slug) {
            this.column = column;
          }
        } else {
          this.column = null;
        }
      })
    );

    this.bag.add(
      this.spaService.course.subscribe((course) => {
        if (course) {
          this.course = course;
          this._contactEmail = course.contact_mail;
          this._contactWhatsapp = course.whatsapp_service;
        } else {
          this._contactWhatsapp = null;
          this._contactEmail = null;
          this.course = null;
        }
      })
    );
  }

  getWhatsAppLink(): string {
    const phone = this.removeWhiteSpaces.transform(
      this.contactWhatsapp.replace('+', '')
    );
    return `https://wa.me/${phone}`;
  }

  getOrgWhatsAppLink() {
    const phone = this.removeWhiteSpaces.transform(
      this.translate.contactPhoneForURL().replace('+', '')
    );
    return `https://wa.me/${phone}`;
  }

  getEmailLink(): string {
    return 'mailto:' + this.contactEmail;
  }

  getOrgEmailLink(): string {
    return 'mailto:' + this.orgContactEmail;
  }

  ngOnDestroy(): void {
    this.bag.dispose();
  }

  ngAfterViewInit(): void {
    this.modalService.registerGlobalModal(this.registerModal);
    this.modalService.registerGlobalModal(this.buyModal);
  }
}
