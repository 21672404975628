import { inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { fromEvent, Observable, of } from 'rxjs';
import { ActivityType, IActivity } from '@models/activity';
import { CourseNavigationContext } from '@services/chapter-navigation.service';
import { SessionStorageService } from '@services/local-storage';
import { LoginService, STORAGE_KEYS } from '@core/auth';
import { WindowRefService } from '@services/window-ref.service';
import { HttpService } from '@core/http';
import { NavigationStart, Router } from '@angular/router';
import { FeatureFlagService } from '@services/feature-flag.service';
import { createIngestionUrl } from '@utils/urlFactory';
import { FeatureFlag } from '@models/feature-flags';


@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private eventBuffer: IActivity[] = [];
  private uploadInterval = null;
  private httpClient: HttpService<void> = inject(HttpService);
  private courseNavigationContext = inject(CourseNavigationContext);
  private sessionStorageService: SessionStorageService = inject(SessionStorageService);
  private loginService: LoginService = inject(LoginService);
  private windowRefService: WindowRefService = inject(WindowRefService);
  private router = inject(Router);
  private featureFlagService = inject(FeatureFlagService);
  private isEnabled = false;

  // For testing purposes
  public get eventBufferSnapshot() {
    return [...(this.eventBuffer.map((activity) => ({...activity})))];
  }

  public add(type: ActivityType, value: string = null) {
    this.push({ type, value });
  }

  public push(activity: Partial<IActivity>) {
    if (!this.isEnabled) {
      return;
    }
    if (this.loginService.isAnonymous.getValue() || !this.loginService.isLoggedIn.getValue()) {
      return;
    }
    this.eventBuffer.push(this.createActivity(activity));
  }

  public createActivity(data: Partial<IActivity>) {
    const activity: IActivity = {
      sessionId: this.getSessionId(),
      timestamp: DateTime.now().toJSDate(),
      path: location.pathname,
      chapterId: this.courseNavigationContext.currentChapter?._id,
      type: undefined,
    }
    return { ...activity, ...data };
  }

  public startAsyncUpload() {
    const interval = 60 * 1000;
    if (this.uploadInterval) {
      clearInterval(this.uploadInterval);
    }
    this.uploadInterval = setInterval(() => {
      this.uploadNow();
    }, interval);
  }

  public stopAsyncUpload() {
    if (this.uploadInterval) {
      clearInterval(this.uploadInterval);
    }
  }

  public uploadNow() {
    if (!this.isEnabled) {
      return;
    }
    const payload = this.pullBuffer();
    if (payload.length === 0) {
      return;
    }
    this.uploadNowHttp(payload).catch(() => {
      this.eventBuffer.push(...payload);
      return of();
    }).subscribe();
  }

  public uploadNowHttp(activities: IActivity[]): Observable<unknown> {
    return this.httpClient.post(
      createIngestionUrl('activities', 'create'), {
      activities: activities,
    }, undefined, () => true);
  }

  // For testing purposes
  public setEnabled(enabled = true) {
    this.isEnabled = enabled;
  }

  public onInit() {
    this.isEnabled = this.featureFlagService.isFeatureEnabled(FeatureFlag.ActivityTracking);
    this.sessionStorageService.setItem(STORAGE_KEYS.SESSION_ID, (Math.random() * 100000000).toFixed(0));

    fromEvent(this.windowRefService.nativeWindow, 'focus').subscribe(() => {
      this.add('open-app');
      this.startAsyncUpload();
    });

    fromEvent(this.windowRefService.nativeWindow, 'blur').subscribe(() => {
      this.add('close-app');
      this.uploadNow();
      this.stopAsyncUpload();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.add('navigation', event.url);
      }
    })

    this.startAsyncUpload();
  }

  public onDestroy() {

  }

  private pullBuffer(maxPull: number = 200) {
    const pull = Math.min(this.eventBuffer.length, maxPull);
    const payload = this.eventBuffer.slice(0, pull);
    this.eventBuffer = this.eventBuffer.slice(pull);
    return payload;
  }

  private getSessionId() {
    return this.sessionStorageService.getItem(STORAGE_KEYS.SESSION_ID);
  }


}


