import { HttpService } from '@core/http';
import { Injectable } from '@angular/core';
import { IChapterUserdata, IColumnUserdata, ICourseUserdata } from '@models/course-userdata';
import { IChapter } from '@models/chapter';
import { createProfileUrl } from '@utils/urlFactory';
import { ProgressState } from '@models/profile';
import { IColumn, ICourse } from '@models/course';
import ISection from '@models/section';
import { map } from 'rxjs/operators';
import { ActivityService } from '@services/activity.service';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {
  constructor(
    private client: HttpService<ICourseUserdata>,
    private activityService: ActivityService,
  ) {}

  public getCourseAndColumnData(course: ICourse) {
    return this.client.post<ICourseUserdata>(
      createProfileUrl(
        'userdata',
        'course', course._id,
        'with-columns'
      ),
      {
        columnIds: course.columns.map((column) => column.id)
      }
    ).pipe(
      map((userdata) => {
        let result: ICourseUserdata;
        if (!userdata) {
          result = {
            courseId: course._id,
            progress: {
              processed: 0,
              understood: 0,
            },
            _id: '',
            userId: '',
            columns: {},
          }
        } else {
          result = userdata;
        }
        course.columns.forEach((column) => {
          result.columns[column.id] = result.columns[column.id] || {
            columnId: column.id,
            chapters: {},
            progress: {
              processed: 0,
              understood: 0,
            }
          };
        });
        result.progress = {
          processed: 0,
          understood: 0,
        };
        Object.values(result.columns).forEach((columnData) => {
          const column = course.columns.find((column) => column.id === columnData.columnId);
          if (!column) {
            delete result.columns[columnData.columnId];
            return;
          }
          result.progress.processed += columnData.progress.processed;
          result.progress.understood += columnData.progress.understood;
        });
        return result;
      })
    );
  }

  public getChapterData(chapter: IChapter) {
    return this.client.get<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
      ),
    );
  }

  public getColumnData(courseId: string, columnId: string) {
    return this.client.get<IColumnUserdata>(
      createProfileUrl(
        'userdata',
        'course', courseId,
        'column', columnId,
      ),
    );
  }

  public bookmarkChapter(chapter: IChapter) {
    this.activityService.push({
      type: 'click',
      value: 'bookmark',
      chapterId: chapter._id,
    });
    return this.client.put<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'bookmark'
      ),
      null
    );
  }

  public unBookmarkChapter(chapter: IChapter) {
    this.activityService.push({
      type: 'click',
      value: 'unbookmark',
      chapterId: chapter._id,
    });
    return this.client.delete<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'bookmark'
      ),
    );
  }

  public bookmarkSection(chapter: IChapter, sectionId: string) {
    this.activityService.push({
      type: 'click',
      value: 'bookmark',
      chapterId: chapter._id,
      sectionId: sectionId,
    });
    return this.client.put<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'section', sectionId,
        'bookmark'
      ),
      null
    );
  }

  public unBookmarkSection(chapter: IChapter, sectionId: string) {
    this.activityService.push({
      type: 'click',
      value: 'unbookmark',
      chapterId: chapter._id,
      sectionId: sectionId,
    });
    return this.client.delete<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', chapter.courseId,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'section', sectionId,
        'bookmark'
      ),
    );
  }

  public setChapterProgress(state: ProgressState, course: ICourse, chapter: IChapter) {
    this.activityService.push({
      type: 'progress',
      value: `${state}`,
      chapterId: chapter._id,
    });
    return this.client.put<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', course._id,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'progress', 'state', state.toString(10),
      ), {});
  }

  public setSectionProgress(state: ProgressState, course: ICourse, chapter: IChapter, section: ISection) {
    this.activityService.push({
      type: 'progress',
      value: `${state}`,
      chapterId: chapter._id,
      sectionId: section._id,
    });
    return this.client.put<IChapterUserdata>(
      createProfileUrl(
        'userdata',
        'course', course._id,
        'column', chapter.columnId,
        'chapter', chapter._id,
        'section', section._id,
        'progress', 'state', state.toString(10),
      ), {});
  }

  public resetColumnProgress(course: ICourse, column: IColumn) {
    this.activityService.push({
      type: 'progress-reset',
      value: `${course._id}/${column.id}`,
    });
    return this.client.delete(
      createProfileUrl(
        'userdata',
        'course', course._id,
        'column', column.id,
        'progress'
      )
    )
  }
}
