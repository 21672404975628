import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpService } from '@core/http';
import {
  ICourse,
  ICourseEnrolmentInput,
  ICourseEnrolmentResponse
} from '@models/course';
import { createProfileUrl } from '@utils/urlFactory';
import { IEnrollment, IRemoveEnrollmentResponse } from '@models/enrollment';
import { IUserAccessState } from '@models/user-access-state';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

interface IVerifyEnrollmentResponse {
  enrollment: IEnrollment;
  examDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {
  public enrollmentExists: BehaviorSubject<boolean | undefined> =
    new BehaviorSubject(undefined);

  constructor(private client: HttpService<ICourse>) {}

  public createExamEnrollment(
    body: ICourseEnrolmentInput
  ): Observable<ICourseEnrolmentResponse | undefined> {
    /**
     * To prevent extra call if enrolment already exists
     */
    if (this.enrollmentExists.getValue()) {
      return of();
    }
    return this.client
      .post<ICourseEnrolmentResponse>(createProfileUrl('enrollment'), body)
      .pipe(
        map((result: ICourseEnrolmentResponse) => {
          this.enrollmentExists.next(true);
          return result;
        })
      );
  }

  public createExamEnrollmentForUser(
    body: ICourseEnrolmentInput
  ): Observable<IUserAccessState | undefined> {
    return this.client
      .post<IUserAccessState>(createProfileUrl('enrollment', 'for-user'), body)
      .pipe(
        map((result: IUserAccessState) => {
          return result;
        })
      );
  }

  public removeEnrollment(courseId: string, userId: string): Observable<IRemoveEnrollmentResponse> {
    return this.client.delete<IRemoveEnrollmentResponse>(
      createProfileUrl('enrollment', courseId, userId),
    );
  }

  public setEnrolmentExists(exists: boolean) {
    this.enrollmentExists.next(exists)
  }

  public verifyEnrollment(courseId: string) {
    return this.client.get<IVerifyEnrollmentResponse>(
      createProfileUrl('course', courseId, 'enrollment'),
      undefined,
      (e) => e.status === HttpStatusCode.PaymentRequired
    ).pipe(
      tap((response) => {
        this.setEnrolmentExists(!!response.enrollment);
      }),
      catchError((err: HttpErrorResponse): Observable<unknown> => {
        if (err.status === HttpStatusCode.PaymentRequired) {
          this.setEnrolmentExists(false);
        }
        return of(err);
      })
    );
  }
}
