import { IMediaItem } from './media';

export enum CommentVisibility {
  admin,
  published,
  deleted,
  inherit
}
export const AllCommentVisibilities = [
  CommentVisibility.published,
  CommentVisibility.admin,
  CommentVisibility.deleted,
  CommentVisibility.inherit
];

export default interface IComment {
  _id: string;
  id?: string;
  repliesTo?: string;
  author_id: string;
  author_name: string;
  title: string;
  text: string;
  date: Date;
  createdAt?: string;
  visibility: CommentVisibility;
  replies: IComment[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chapter: string | any;
  code?: number;
  media?: IMediaItem[];
  responseRecommendation?: string;
}
